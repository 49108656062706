import { Component, OnInit, OnDestroy } from '@angular/core';

// Router
import { ActivatedRoute } from '@angular/router';

// RxJS
import { Subscription } from 'rxjs/internal/Subscription';

// Services
import { ConfirmViewNotificationService } from './services/confirm-view-notification.service';

@Component({
  selector: 'app-confirm-view-notification',
  templateUrl: './confirm-view-notification.component.html',
  styleUrls: ['./confirm-view-notification.component.scss']
})
export class ConfirmViewNotificationComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription[] = [];

  check: boolean = false;
  loadingConfirmation: boolean = true;

  constructor(
    private confirmViewNotificationService: ConfirmViewNotificationService,
    private route: ActivatedRoute,
  ) { }

  ngOnDestroy(): void {
    this.subscriptions.forEach( ( sub: Subscription ) => sub.unsubscribe() )
  }

  ngOnInit(): void {
    this.chargeRouteParams();
  }

  private chargeRouteParams(): void {
    this.subscriptions.push(
      this.route.params.subscribe( ( params: any ) => {
        const token = params.token;
        this.confirmView(token);
      })
    );
  }

  private confirmView( token: string ): void {
    this.subscriptions.push(
      this.confirmViewNotificationService.viewNotification(token).subscribe(
        ( resp: any ) => {
          this.loadingConfirmation = false;
          this.check = true;
        },
        ( err: any ) => {
          this.loadingConfirmation = false;
          this.check = false;
        }
      )
    );
  }
}
