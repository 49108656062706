import { Injectable } from '@angular/core';

// Http
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';

// RxJS
import { map } from 'rxjs/internal/operators/map';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptorService implements HttpInterceptor {

  constructor() {}

  intercept<T>( request: HttpRequest<T>, next: HttpHandler ): Observable<HttpEvent<T>> {
    const token: string | null = localStorage.getItem('token');
    const tokenWorkflow: string | null = localStorage.getItem('token-workflow');

    if ( token ) request = request.clone({ headers: request.headers.set('Authorization', `Bearer ${token}`) });
    if ( tokenWorkflow ) request = request.clone({ headers: request.headers.set('AuthorizationWorkflow', `${tokenWorkflow}`) });

    request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
    request.clone({ headers: request.headers.set('Accept', 'application/json') });
    request.clone({ headers: request.headers.set('Cache-Control', 'no-cache') });
    request.clone({ headers: request.headers.set('Pragma', 'no-cache') });

    return next.handle(request).pipe(
      map( ( event: any ) => event )
    );
  } 
}