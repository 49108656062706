import { Injectable } from '@angular/core';

// Router
import { CanActivate, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private router: Router,
  ) {}

  canActivate() {
    if ( !localStorage.getItem('token') ) {
      this.router.navigate(['/auth']);
      return false;
    }

    return true;
  }
}
